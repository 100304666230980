import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  bg,
  foodGallery,
  mobileGallery,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView image={`url(${bg}) center / contain repeat`}>
          <CFView column center>
            <CFImage src={mobileGallery} w="100%" alt="Food Gallery" p="10px" />
          </CFView>
          <Slider {...{ ...settings, slidesToShow: 1 }}>
            <CFImage
              src={gallery1}
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={gallery2}
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={gallery3}
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
          </Slider>
          <Slider {...{ ...settings, slidesToShow: 1 }}>
            <CFImage
              src={gallery4}
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={gallery5}
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={gallery6}
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
          </Slider>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="0 auto">
          <CFImage src={foodGallery} w="100%" alt="Food Gallery" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
