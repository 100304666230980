import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { bg, mobilePromotion, promotions, promo1, promo2, promo3 } from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView image={`url(${bg}) center / contain repeat`}>
          <CFView column center mt="-1px">
            <CFImage src={mobilePromotion} w="100%" alt="Promotions" p="10px" />
          </CFView>
          <Slider {...{ ...settings, slidesToShow: 1 }}>
            <CFImage
              src={promo1}
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={promo2}
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={promo3}
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
          </Slider>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px">
          <CFImage src={promotions} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
