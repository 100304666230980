import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#D40F33" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink target="_blank" href="https://www.yelp.ca/biz/coral-court-szechuan-cuisine-north-vancouver">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Coral Court Chinese Yelp"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.zomato.com/vancouver/coral-court-szechuan-cuisine-lower-lonsdale/menu">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Coral Court Chinese Zomato"
              />
            </CFLink>
            {/*
            <CFLink href="https://www.instagram.com/88noodles/">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Coral Court Chinese Instagram"
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#D40F33" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink target="_blank" href="https://www.yelp.ca/biz/coral-court-szechuan-cuisine-north-vancouver">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Coral Court Chinese Yelp"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.zomato.com/vancouver/coral-court-szechuan-cuisine-lower-lonsdale/menu">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Coral Court Chinese Zomato"
              />
            </CFLink>
            {/*
            <CFLink href="https://www.instagram.com/88noodles/">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Coral Court Chinese Instagram"
                hover
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
